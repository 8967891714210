.content-center h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
  letter-spacing: -0.02em;
  margin: 0px;
}

.box-header-top {
  display: inline-flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.bt-circle {
  display: flex;
  width: 42px;
  height: 42px;
  align-items: center;
  justify-content: center;
  justify-items: center;
  font-weight: bold;
  font-size: 23px;
  cursor: pointer;
  background-image: url('../../../public/Images/icon-back.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.bt-reload {
  display: flex;
  width: 25px;
  height: 25px;
  align-items: center;
  justify-content: center;
  justify-items: center;
  font-weight: bold;
  font-size: 23px;
  cursor: pointer;
  background-image: url('../../../public/Images/icon_load.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: 1em;
}
.bt-reload-static {
  display: flex;
  margin-top: 10px;
  width: 18px;
  height: 18px;
  align-items: center;
  justify-content: center;
  justify-items: center;
  font-weight: bold;
  font-size: 23px;
  cursor: pointer;
  background-image: url('../../../public/Images/icon-reload-static.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: 1em;
}

.bt-reload-dynamic {
  display: flex;
  margin-top: 10px;
  width: 18px;
  height: 18px;
  align-items: center;
  justify-content: center;
  justify-items: center;
  font-weight: bold;
  font-size: 23px;
  cursor: pointer;
  background-image: url('../../../public/Images/icon-reload-dynamic.gif');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: 1em;
}

.bt-circle:hover {
  background-image: url('../../../public/Images/icon-back-hover.png');
}

.box-img-bg {
  position: absolute;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 0;
}

.row-mb {
  margin-bottom: 15px;
}

.box-screen {
  position: relative;
}

.box-wrapper {
  background: #ffffff;
  border: 1px solid #e6e4e4;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 15px;
  margin-bottom: 15px;
  box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.05);
  position: relative;
  z-index: 2;
}

.label-title {
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  color: #333;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-items: center;
}

.label-title-error {
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  color: #ee3900;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-items: center;
}

.form-control-valid {
  color: #3c3c3c !important;
  border: 1px solid #e0e0e0 !important;
  font-size: 16px !important;
}

.form-control-valid:focus {
  box-shadow: none !important;
  border: 1px solid #e0e0e0 !important;
}

.form-control-error {
  color: #ee3900 !important;
  border: 1px solid #ee3900 !important;
  font-size: 16px !important;
}

.form-control-error:focus {
  box-shadow: none !important;
  border: 1px solid #ee3900 !important;
}

.form-ct {
  height: 42px;
}

.error-note {
  font-size: 10px;
  font-weight: 300;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: left;
  color: #ee3900;
  margin-top: 4px;
}

.input-password > .input-group-text {
  background-color: #fff !important;
  cursor: pointer;
}

.input-password > .error {
  border-color: #ee3900 !important;
  border-left: none !important;
  background-color: #fff !important;
  cursor: pointer;
}

.input-password input {
  border-right: 0px !important;
}

.text-more {
  font-size: 12px;
  font-weight: 300;
  color: #333333;
}

.bt-submit-disabled {
  width: 137px;
  height: 42px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 9px 18px;
  border-radius: 48px;
  box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.05);
  background-color: #e0e0e0;
  border: none;
  color: #bdbdbd;
}

.bt-submit {
  width: 137px;
  height: 42px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 9px 18px;
  border-radius: 48px;
  box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.05);
  background-color: #ffe426;
  border: none;
  color: #3c3c3c;
}
