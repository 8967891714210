.range-date-picker {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.date-picker {
  width: 45%;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 1px;
  font-size: 16px;
  color: #333;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker {
  border: 1px solid #e0e0e0;
  font-size: 16px;
}

.react-datepicker__input-container input {
  width: 100%;
  border: 1px solid #c3c3c3;
  outline: none;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  height: 40px;
  border-radius: 4px;
}

.react-datepicker__month-container {
  padding: 1px;
}

.react-datepicker__header {
  background-color: #ffe426;
}

.react-datepicker__day {
  color: #333;
}

.react-datepicker__day--selected {
  background-color: #ffe426;
  color: #fff;
}

.react-datepicker__day--keyboard-selected {
  background-color: #ffe426;
  color: #fff;
}

.react-datepicker__day--in-selecting-range {
  background-color: #ffe426;
}

.react-datepicker__day--range-start,
.react-datepicker__day--range-end {
  background-color: #ffe426;
  color: #fff;
}
