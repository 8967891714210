a,
a:hover {
  text-decoration: unset !important;
  color: unset !important;
}

.header-fix {
  height: 100px;
  padding: 24px 5px;
}

.bg-light-wh {
  background: #f5f5f5;
  box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.15) !important;
  z-index: 4;
}

.logo-m {
  height: 47px;
  margin-top: -1px;
}

.box-menu-list {
  display: inline-flex;
  justify-content: flex-end;
  align-items: stretch;
  justify-items: center;
  padding-right: 27px;
}

.box-menu-list .btn-menu-icon {
  height: 52px;
  margin-left: 15px;
}

.box-menu-list .btn-menu-icon > .btn-menu-list {
  background-color: #fff;
  box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.05);
  color: #3c3c3c;
  border-radius: 48px;
  font-size: 13px;
  height: 52px;
  cursor: pointer;
  display: flex;
  align-self: auto;
  min-height: auto;
  align-items: center;
  justify-content: center;
  padding: 14px 18px;
  text-align: center;
}

.box-menu-list .btn-menu-icon > .btn-menu-list:hover,
.box-menu-list .btn-menu-icon > .btn-menu-list.active,
.btn-menu-icon:hover > .btn-menu-list {
  background: #ffe426;
}

.box-menu-list .btn-menu-icon > .box-icon-menu {
  background-size: contain;
  padding: 0px !important;
  width: 52px;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: none;
  border-radius: unset;
  height: 52px;
  cursor: pointer;
}

.menu-list-nav {
  min-width: 210px !important;
  box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  border: 0px;
  padding: 0px !important;
}

.menu-list-nav li:first-child {
  border-top-right-radius: 12px !important;
  border-top-left-radius: 12px !important;
}

.menu-list-nav li .dropdown-item {
  padding: 4px 16px;
  font-size: 14px;
  letter-spacing: -0.32px;
  text-align: left;
  color: #3c3c3c;
}

.menu-list-nav li.first-child .dropdown-item:hover {
  color: #3c3c3c;
  background-color: rgba(255, 228, 38, 0.4);
  border-top-right-radius: 12px !important;
  border-top-left-radius: 12px !important;
}

.menu-list-nav li.last-child .dropdown-item:hover {
  color: #3c3c3c;
  background-color: rgba(255, 228, 38, 0.4);
  border-bottom-right-radius: 12px !important;
  border-bottom-left-radius: 12px !important;
}

.dropdown-menu.menu-list-sm {
  min-width: 130px !important;
}

.dropdown-menu.menu-list-lng {
  min-width: 70px !important;
}

.bt-icon-alert {
  background-image: url('../../../../public/Images/icon-menu/Menu_Notif_Default.png');
}

.bt-icon-alert:hover,
.bt-icon-alert.active {
  background-image: url('../../../../public/Images/icon-menu/Menu_Notif_Active.png');
}

.bt-icon-user {
  background-image: url('../../../../public/Images/icon-menu/Menu_Profile_Default.png');
}

.bt-icon-user:hover,
.bt-icon-user.active {
  background-image: url('../../../../public/Images/icon-menu/Menu_Profile_Active.png');
}

.btn-icon-download-pdf {
  background-image: url('../../../../public/Images/icon-menu/Menu_Download_pdf.png');
}

.box-menu-left {
  position: fixed;
  left: 0%;
  top: 0%;
  bottom: 0%;
  background: #f5f5f5;
  border-radius: 0px;
  width: 100px;
  box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.15) !important;
  z-index: 9;
  padding-top: 115px;
}

.box-sub-menu {
  display: none;
  position: absolute;
  width: 307px;
  box-shadow: 3px 7px 3px rgba(0, 0, 0, 0.15) !important;
  background: #f5f5f5;
  border-radius: 0px;
  top: 99px;
  left: 83px;
  height: 100%;
  z-index: 25;
  transition: transform 0.35s;
}

.box-sub-menu.active {
  display: block;
}

.box-text-menu-admin {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 39px;
  text-align: left;
}

.box-subtext-menu-admin {
  margin: 30px 15px 0px 10px;
}

.box-radius-sm,
.box-radius-sm-2,
.box-radius-sm-3 {
  display: block;
  position: absolute;
  left: 0;
  width: 125px;
  height: 100px;
  top: 0px;
  background-color: #f5f5f5;
  z-index: 20;
}

.box-radius-sm {
  padding: 24px 15px;
  margin-left: 8px;
}

.box-radius-sm-2 {
  width: 35px;
  height: 20px;
  left: 100px;
  top: 99px;
  background: #fff;
  -webkit-box-shadow: inset 9px 11px 8px -7px rgb(217 217 217);
  -moz-box-shadow: inset 9px 11px 8px -7px rgb(217 217 217);
  box-shadow: inset 9px 10px 8px -7px rgb(217 217 217);
  border-radius: 12px 0px 0px 0px;
}

.box-radius-sm-2.expand {
  left: 250px;
  top: 100px;
  z-index: 28 !important;
}

.box-radius-sm-3 {
  opacity: 0;
  background-color: whitesmoke;
  z-index: 26;
  left: 240px;
  top: 98px;
  width: 20px;
  height: 20px;
}

.box-radius-sm-3.active {
  opacity: 1;
}

.wrapper-box-menu-left {
  text-align: center;
}

.box-menu-block-admin {
  width: 45px;
  height: 45px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 27px;
  margin-bottom: 15px;
  position: relative;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.05);
  border-radius: 50%;
}

.bt-icon-dashboard {
  background-image: url('../../../../public/Images/icon-menu/Menu_Dashboard_Default.png');
}

.bt-icon-dashboard:hover,
.bt-icon-dashboard.active {
  background-image: url('../../../../public/Images/icon-menu/Menu_Dashboard_Active.png');
}

.bt-icon-non-attr {
  background-image: url('../../../../public/Images/icon-menu/Menu_NonAttr_Default.png');
}

.bt-icon-non-attr:hover,
.bt-icon-non-attr.active {
  background-image: url('../../../../public/Images/icon-menu/Menu_NonAttr_Active.png');
}

.bt-icon-encour {
  background-image: url('../../../../public/Images/icon-menu/Menu_EnCours_Default.png');
}

.bt-icon-encour:hover,
.bt-icon-encour.active {
  background-image: url('../../../../public/Images/icon-menu/Menu_EnCours_Active.png');
}

.bt-icon-enretard {
  background-image: url('../../../../public/Images/icon-menu/Menu_Retard_Default.png');
}

.bt-icon-enretard:hover,
.bt-icon-enretard.active {
  background-image: url('../../../../public/Images/icon-menu/Menu_Retard_Active.png');
}

.bt-icon-validee {
  background-image: url('../../../../public/Images/icon-menu/Menu_Validee_Default.png');
}

.bt-icon-validee:hover,
.bt-icon-validee.active {
  background-image: url('../../../../public/Images/icon-menu/Menu_Validee_Active.png');
}

.bt-icon-facturation {
  background-image: url('../../../../public/Images/icon-menu/facturation/icon_menu_facturation.png');
}

.bt-icon-facturation:hover,
.bt-icon-facturation.active {
  background-image: url('../../../../public/Images/icon-menu/facturation/icon_menu_facturation_ACTIVATED.png');
  background-size: cover;
}

.bt-icon-smart-with-review-orders {
  background-image: url('../../../../public/Images/icon-menu/Menu_SmartWithReview_Default.png');
}

.bt-icon-smart-with-review-orders:hover,
.bt-icon-smart-with-review-orders.active {
  background-image: url('../../../../public/Images/icon-menu/Menu_SmartWithReview_Active.png');
}

.bt-icon-smart-orders {
  background-image: url('../../../../public/Images/icon-menu/Menu_Smart_Default.png');
}

.bt-icon-smart-orders:hover,
.bt-icon-smart-orders.active {
  background-image: url('../../../../public/Images/icon-menu/Menu_Smart_Active.png');
}

.bt-icon-redac {
  background-image: url('../../../../public/Images/icon-menu/Menu_Redacteur_Default.png');
}

.bt-icon-redac:hover,
.bt-icon-redac.active {
  background-image: url('../../../../public/Images/icon-menu/Menu_Redacteur_Active.png');
}

.bt-icon-util {
  background-image: url('../../../../public/Images/icon-menu/Menu_Utilisateur_Default.png');
}

.bt-icon-util:hover,
.bt-icon-util.active {
  background-image: url('../../../../public/Images/icon-menu/Menu_Utilisateur_Active.png');
}

.flag-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flag-img-container img {
  max-width: 100%;
  height: 20px;
}

.flag-img-container-country img {
  max-width: 100%;
  height: 30px;
}

@media screen and (max-width: 768px) {
  .wrapper-content {
    padding: 40px 20px 50px 120px !important;
    width: 100%;
  }

  .logo-m {
    height: 25px !important;
    margin-top: 8px !important;
  }
}
